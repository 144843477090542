const Header = ({title,children}) => {
    return <>
        <header>
            <nav className="navbar bg-light mb-5">
                <div className="container py-4">
                    <a className="navbar-brand text-dark">{title}</a>
                    <div className="">
                        {children}
                    </div>
                </div>
            </nav>
        </header>
    </>
}

export default  Header