import {
    BrowserRouter,
    Routes,
    Route,
} from "react-router-dom";



import {AddNewProduct, NotFound, ProductListing} from "./pages";



function App() {

  return (
    <BrowserRouter>

        <Routes>
            <Route path={"/"} element={<ProductListing/>}/>
            <Route path={"/add-product"} element={<AddNewProduct/>}/>
            <Route path={"*"} element={<NotFound/>}/>
        </Routes>
    </BrowserRouter>
  );
}

export default App;
