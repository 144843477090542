const baseUrl = "https://scandiweb-api.deesuntech.com.ng/"



export const getRequest = async (url) => {
    return await fetch(`${baseUrl}${url}`, {
      method: 'GET',
      headers: {
          Accept: 'application/json',
      }
  })
      .then(response => response.json())
      .then(json => json);
}

export const postRequest =async (url,postData) => {
   return await fetch(`${baseUrl}${url}`,{
        method:'POST',
        headers:{
            Accept : 'application/json',
            // 'Content-Type' :'application/json'
        },
        body: JSON.stringify(postData)
    })
       .then(response => response.json())
        .then(json => json);

}

