import {Link} from "react-router-dom";
import {Header} from "../components";

const NotFound = () => {
    return <>

        <Header title={'Page Not Found'}>

        </Header>
        <div className={'container'}>
            <div className={'text-center'}>
                <h4>oops! page not found</h4>
                <Link className={'btn btn-primary'} to={'/'}>Go back Home</Link>
            </div>

        </div>
    </>

}

export  default  NotFound