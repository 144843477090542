import {useState} from "react";
import { useNavigate } from 'react-router-dom';
import {FormHelper, Header, LabelWrapper} from "../components";
import {postRequest} from "../request";

const defaultFormValues = {
    sku: "",
    name: "",
    price: "",
    size: "", //type == 'DVD'
    //Furniture
    height : "",
    width : "",
    length: "",
    //Book
    weight : ""
};


const AddNewProduct = () => {

    const navigate = useNavigate();

    const [selectedSwitcher, setSelectedSwitcher] = useState('')
    const [formValues, setFormValues] = useState(defaultFormValues)
    const [errorBags,setErrorBags] = useState([])

    //handles the selected switcher
    const handleTypeSwitcher = (value) => {
        setSelectedSwitcher(value)
    }


    //onchange for input field
    const handleInputChange = (e) => {
        const value = e.target.value;
        const name = e.target.name

        setFormValues({
            ...formValues,
            [name]: value
        });

    }

    const onSubmit = async (e) => {
        setErrorBags([])
        e.preventDefault()
        let errorMessages = []
        //validating empty field
        if(formValues.sku==="" || formValues.name==="" ||formValues.price==="" ||selectedSwitcher==="" ) errorMessages.push('Please, submit required* data')
        if(!formValues.price>0 ) errorMessages.push('price value should be greater than zero')
        //
        switch (selectedSwitcher) {
            case 'DVD':
                //size is important
                if(!formValues.size && !Number(formValues.size)>0) errorMessages.push('Please, provide the data of indicated type (size)')
                break;
            case 'Furniture':
                //height ,width,length are  important
                if(!formValues.height && !formValues.length && !formValues.width) errorMessages.push('Please, provide the data of indicated type (width/length/height)')
                break;
            case 'Book':
                //weight is important
                if(!formValues.weight) errorMessages.push('Please, provide the data of indicated type (weight)')
                break;
            default:
                break;
        }

        if(errorMessages.length>0){
            setErrorBags(errorMessages)
        }
        else{
            const postData  = {
                "sku":formValues.sku,
                "name": formValues.name,
                "product_type" :selectedSwitcher,
                "price": formValues.price,
                "size": formValues.size,
                "height": formValues.height,
                "width": formValues.width,
                "length": formValues.length,
                "weight": formValues.weight
            }

            const res = await  postRequest('product/add',postData)
            if(res.status==='success'){
                //redirect to homepage
                navigate('/')
            }else {
                errorMessages.push(res.message)
                setErrorBags(errorMessages)
            }
        }
    }



    return <>
        <form id={'product_form'}>
            <Header title={'Product Add'}>
                <button type={'submit'} onClick={(e) => onSubmit(e) } className={'btn btn-dark mr-2 px-5'}> Save</button>
                <button type={'button'} className={'btn btn-outline-danger'} onClick={()=> navigate('/')}>Cancel</button>
            </Header>

            <div className={'container'}>

                <div className={'row'}>
                    <div className={'col-12'}>
                        {errorBags.length>0 && errorBags.map((error,i=0)=> <div key={i} className={'alert alert-danger'}> {error} </div>)}
                        {/*SKU input field begins here*/}
                        <div className={'row align-items-center mb-4'}>
                            <LabelWrapper text={'SKU'}/>
                            <div className={'col-sm-12 col-md-8 col-lg-5 col-xl-8'}>
                                <input
                                    type={'text'}
                                    defaultValue={formValues.sku}
                                    id={"sku"}
                                    name={'sku'}
                                    onChange={handleInputChange}
                                    className={'form-control'}
                                    required={true}
                                />
                                <FormHelper desc={'please enter product sku.'}/>
                            </div>
                        </div>
                        {/*SKU input field ends here*/}

                        {/*Name input field begins here*/}
                        <div className={'row align-items-center mb-4'}>
                            <LabelWrapper text={'Name'}/>
                            <div className={'col-md-8 col-lg-5 col-xl-8'}>
                                <input type={'text'}
                                       defaultValue={formValues.name}
                                       name={'name'}
                                       id={'name'}
                                       className={'form-control'}
                                       onChange={handleInputChange}
                                       placeholder={'e.g Mac book Pro'}
                                       required={true}
                                />
                                <FormHelper desc={'please enter product name.'}/>
                            </div>
                        </div>
                        {/*Name input field ends here*/}

                        {/*Price input field begins here*/}
                        <div className={'row  align-items-center mb-4'}>
                            <LabelWrapper text={'Price ($)'}/>
                            <div className={'col-md-8 col-lg-5 col-xl-8'}>
                                <input
                                    type={'number'}
                                    defaultValue={formValues.price}
                                    name={'price'}
                                    id={'price'}
                                    className={'form-control'}
                                    onChange={handleInputChange}
                                    required={true}
                                />
                                <FormHelper desc={'please enter product price in dollar.'}/>
                            </div>
                        </div>
                        {/*Price input field ends here*/}

                        {/*Type Switcher field begins here*/}
                        <div className={'row align-items-center mb-4'}>
                            <LabelWrapper text={'Type Switcher'}/>
                            <div className={'col-md-8 col-lg-5 col-xl-8'}>
                                <select id={'productType'}
                                        name={'productType'}
                                        onChange={(e) => handleTypeSwitcher(e.target.value)}
                                        defaultValue={selectedSwitcher}
                                        className={'custom-select w-100'}>
                                    <option value={''}>Type Switcher</option>
                                    <option id={'DVD'} value={'DVD'}>DVD</option>
                                    <option id={'Furniture'} value={'Furniture'}>Furniture</option>
                                    <option id={'Book'} value={'Book'}>Book</option>
                                </select>
                                <FormHelper desc={'please select product type.'}/>
                            </div>
                        </div>
                        {/*Type Switcher field ends here*/}


                        {/* different switcher salable   */}
                        {
                            selectedSwitcher === 'DVD'
                                ?
                                <div className={'row align-items-center mb-4'}>
                                    <LabelWrapper text={'Size(MB)'}/>
                                    <div className={'col-md-8 col-lg-5 col-xl-8'}>
                                        <input type={'text'}
                                               defaultValue={formValues.size}
                                               name={'size'}
                                               id={'size'}
                                               className={'form-control'}
                                               onChange={handleInputChange}
                                               required={true}
                                        />
                                        <FormHelper desc={'please enter product size in MB.'}/>
                                    </div>
                                </div>

                                :
                                selectedSwitcher === 'Furniture'
                                    ?
                                    <>
                                        <div className={'row align-items-center mb-4'}>
                                            <LabelWrapper text={'Height(CM)'}/>
                                            <div className={'col-md-8 col-lg-5 col-xl-8'}>
                                                <input
                                                    type={'number'}
                                                    defaultValue={formValues.height}
                                                    min={0}
                                                    name={'height'}
                                                    id={'height'}
                                                    className={'form-control'}
                                                    onChange={handleInputChange}
                                                    required={true
                                                }
                                                />
                                                <small className="form-text text-muted">please provide height in CM.</small>
                                            </div>
                                        </div>
                                        {/*  */}
                                        <div className={'row  align-items-center mb-4'}>
                                            <LabelWrapper text={'Width(CM)'}/>
                                            <div className={'col-md-8 col-lg-5 col-xl-8'}>
                                                <input
                                                    type={'number'}
                                                    defaultValue={formValues.width}
                                                    min={0}
                                                    name={'width'}
                                                    id={'width'}
                                                    className={'form-control'}
                                                    onChange={handleInputChange}
                                                    required={true}
                                                />
                                                <FormHelper desc={'please provide width in CM.'}/>
                                            </div>
                                        </div>
                                        {/*    */}
                                        <div className={'row  align-items-center mb-4'}>
                                            <LabelWrapper text={'Length(CM)'}/>
                                            <div className={'col-md-8 col-lg-5 col-xl-8'}>
                                                <input
                                                    type={'number'}
                                                    min={0}
                                                    defaultValue={formValues.length}
                                                    name={'length'}
                                                    id={'length'}
                                                    className={'form-control'}
                                                    onChange={handleInputChange}
                                                    required={true}
                                                />
                                                <FormHelper desc={'please provide length in CM.'}/>
                                            </div>
                                        </div>
                                    </>
                                    :
                                    selectedSwitcher === 'Book'
                                        ?
                                        <>
                                            <div className={'row   mb-4'}>
                                                <LabelWrapper text={'Weight(KG)'}/>
                                                <div className={'col-md-8 col-lg-5 col-xl-8'}>
                                                    <input
                                                        type={'number'}
                                                        min={0}
                                                        name={'weight'}
                                                        defaultValue={formValues.weight}
                                                        id={'weight'}
                                                        className={'form-control'}
                                                        onChange={handleInputChange}
                                                        required={true}
                                                    />
                                                    <FormHelper desc={'Please provide Weight in KG.'}/>
                                                </div>
                                            </div>
                                        </>
                                        :
                                        <></>
                        }
                    </div>
                </div>
            </div>

        </form>
    </>
}

export default AddNewProduct