import {useEffect, useState} from "react";
import { useNavigate } from 'react-router-dom';
import {Header} from "../components";
import {getRequest, postRequest} from "../request";

const ProductListing = () => {
    const navigate = useNavigate();
    const [products, setProducts] = useState([])
    const [selectedProduct, setSelectedProduct] = useState([])

    useEffect( ()=> {
         getProducts()
    },[])

    const getProducts = async () => {
        const res =  await getRequest('product')
        console.log(res)
        setProducts(res.data)
    }

    //handles final submission of select product
    const massDelete = async () => {
        if(selectedProduct.length>0){
            const res = await postRequest('product/delete',selectedProduct)
            if(res.status==="success"){
                setProducts(res.data)
            }
        }
    }

    //add/filter selected/unselect product from items to be deleted
    const handleDelete = (e) => {
        const product_id = Number(e.target.name);
        const isChecked = e.target.checked;

        if(isChecked){
            setSelectedProduct([...selectedProduct,product_id])
        }else{
            let newData = selectedProduct.filter((item)=> product_id !== item)
            setSelectedProduct(newData)
        }
    }

    return <>
        <Header  title={'Product List'}>
            <button type={'button'} className={'mr-3 btn btn-primary'} onClick={ ()=> navigate('/add-product')}> ADD </button>
            <button type={'button'} id={"delete-product-btn"} className={' btn btn-outline-danger'} onClick={()=>massDelete()}>MASS DELETE</button>
        </Header>
        <div className={'container'}>
            <div className={'row'}>
                {products?.length>0 ? products?.map((product,i=0)=>
                        <div key={i} className={'col-sm-12 col-md-6 col-lg-4 col-xl-3  mb-3'}>
                            <div className={'card px-5 py-4'}>
                                <input
                                    onChange={(e)=> handleDelete(e)}
                                    className={'delete-checkbox text-left form-check-input'}
                                    type={'checkbox'}  name={product.id}/>
                                <div className={'text-center'}>
                                    <h6>{product.sku}</h6>
                                    <p> {product.name} </p>
                                    <p> {product.price} $ </p>
                                    <small>  {product.product_type === 'Book' ?
                                        `Weight: ${product.weight} KG` :
                                        product.product_type==='Furniture' ?
                                            `Dimension: ${product.height}x${product.width}x${product.length}`:
                                            `Size: ${product.size} MB`}
                                    </small>
                                </div>

                            </div>
                        </div>
                    )
                    :
                    <h6 className={'text-muted'}>No Product Available</h6>
                }
            </div>
        </div>
    </>
}

export default  ProductListing